/* src/components/commentForm/CommentForm.css */

.comment-form {
    margin-top: 20px;
}

.comment-form textarea {
    width: calc(100% - 20px);
    height: 80px;
    padding: 10px;
    resize: vertical;
    font-size: 16px;
    font-family: "Garamond serif";
}

.comment-form button {
    margin-top: 10px;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    border: none;
    font-size: 16px;
    font-family: "Garamond serif";
    background-color: none;
    border-bottom: 2px solid #ccc;
}