.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: "Garamond", serif;
}

.not-found h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #0e153a;
}

.not-found p {
    font-size: 18px;
    margin-bottom: 20px;
    color: #707070;
}

.not-found a {
    display: inline-block;
    margin-right: 20px;
    font-size: 14px;
    color: #707070;
    transition: all 0.3s ease-in-out;
}

.not-found a:hover {
    color: #0e153a;
}

.fixed-bottom {
    position: fixed;
    bottom: 14px;
}
  