/* Blog.css */

.blog-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  font-family: "Garamond", serif;
}

.blog-page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  width: 100%;
  padding-top: 60px;
  min-height: calc(100vh - 60px);
}

.blog-page-header h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.blog-page-header p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #707070;
}

/* Contenedor para el título y el dropdown */
.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  min-height: 50px;
  max-height: 50px !important;
  /* Ajusta según necesidad */
}

.section-header div {
  display: flex;
  align-items: center;
}

.section-header div select {
  margin: 0px;
}

.section-header h2 {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
}

.section-header label {
  font-size: 16px;
  color: #707070;
  margin-right: 15px;
}

.category-dropdown {
  padding: 8px 30px 8px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'%3E%3Cpath fill='%23707070' d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  font-family: "Garamond serif";
}

.category-dropdown:focus {
  outline: none;
  box-shadow: 0 0 5px #007BFF;
}

.blog-page-body {
  width: 90%;
  display: flex;
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  margin: auto;
  overflow-y: hidden;
  flex-direction: row; /* Default to row */
}

.blog-section,
.microblogging-section {
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  margin: 0px auto;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.blog-section>div,
.microblogging-section>div {
  overflow-y: scroll;
  flex: 1 1 auto;
  padding-right: 1vw;
  max-height: calc(100vh - 120px);
}

.blog-section h2,
.microblogging-section h2 {
  cursor: pointer;
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.microblogging-section {
  width: 20%;
  margin-right: 3%;
}

.blog-section {
  width: 77%;
  margin-right: 3%;
}

/* Media Queries para Responsividad */
@media (max-width: 768px) {
  .section-header {
    flex-direction: row;
    align-items: center;
    height: auto;
  }

  .category-dropdown {
    margin-top: 10px;
    width: 100%;
  }

  .blog-page-body {
    flex-direction: column;
  }

  .blog-section.open,
  .microblogging-section.open {
    width: 100%;
    /* flex: 1 1 auto; */
    min-height: fit-content;
  }

  .blog-section,
  .microblogging-section {
    width: 100%;
    margin-right: 0;
    min-height: auto;
    max-height: none;
  }

  .post-image {
    margin: auto auto 20px auto;
    max-width: 100%;
    max-height: 100%;
  }

  /* Ajustes para el filtro de categorías en dispositivos móviles */
  .category-dropdown {
    width: 100%;
  }
}

/* Estilo para mensaje de error */
.error-message {
  color: red;
  margin-top: 20px;
  text-align: center;
}