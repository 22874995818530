/* src/components/postDetail/PostDetail.css */

.post-detail {
    max-width: 90%;
    margin: auto;
    padding: 60px 0px 40px 0px;
    min-height: calc(100vh - 60px);
    font-family: "Garamond serif";
}

.post-detail-image img {
    width: 100%;
    height: auto;
}

.post-detail-content h1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.post-detail-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.post-detail-content p {
    font-size: 16px;
    color: #707070;
}

.post-detail-stats {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.post-detail-stats button {
    margin-right: 20px;
    font-size: 16px;
    cursor: pointer;
    background: none;
    border: 1px solid #ccc;
    padding: 10px;
}

.post-detail-stats span {
    font-size: 16px;
}

.post-like-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px; /* separacion del contador de vistas */
}

.heart-icon {
    font-size: 14px;
}

.heart-icon.liked {
    color: #d9534f; /* color rojizo para indicar liked */
}

.like-count {
    margin-left: 5px; /* espacio entre el icono y el número */
}

.post-views-container {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
}

.post-views-container .heart-icon {
    font-size: 14px;
    margin-right: 5px;
}

.views-count {
    font-size: 12px;
}

@media screen and (min-width: 768px) {
    .post-detail {
        max-width: 42%;
        padding: 100px 0px 40px 0px;
    }
}