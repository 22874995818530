.menu-container {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    height: 60px;
    width: 100%;
    z-index: 1000;
}

.menu-button {
    margin: 0 20px;
    padding: 10px 0;
    font-size: 16px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-bottom: 0px solid transparent;
    font-family: Georgia, serif; /* Agrega esta línea para cambiar la fuente a Garamond */
}

.menu-button:hover {
    border-bottom: 3px solid #0e153a;
    padding-bottom: 10px; /* Agrega un poco de espacio entre el texto y la línea */
}

.base-computing-menu-button {
    margin: 0 20px;
    padding: 10px 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-bottom: 0px solid transparent;
}

.base-computing-menu-button img {
    width: 120px;
}

.hamburger-menu-button {
    display: inline-block;
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
}

.hamburger-menu-icon {
    display: block;
    width: 20px;
    height: 2px;
    background-color: #0e153a;
    border-radius: 2px;
    position: relative;
}

.hamburger-menu-icon:before,
.hamburger-menu-icon:after {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    background-color: #0e153a;
    border-radius: 2px;
    position: absolute;
}

.hamburger-menu-icon:before {
    top: -6px;
}

.hamburger-menu-icon:after {
    top: 6px;
}

.menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 999;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.show-menu {
    transform: translateX(0%);
}

@media (min-width: 768px) {
    .menu-container {
        justify-content: center;
    }

    .hamburger-menu-button {
        display: none;
    }

    .menu {
        position: static;
        flex-direction: row;
        background-color: transparent;
        transform: translateX(0%);
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
    }

    .menu-button {
        margin: 0 20px;
        padding: 10px 0;
        font-size: 16px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border-bottom: 0px solid transparent;
        font-family: Georgia, serif;
    }

    .menu-button:hover {
        border-bottom: 3px solid #0e153a;
        padding-bottom: 10px;
    }
}