/* Comment.css */
.comment {
    margin-top: 10px;
    padding-left: 20px;
    border-left: 1px solid #ccc;
    font-family: "Garamond serif";
}

.comment-actions {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.comment-actions .like-button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.comment-actions .like-button.liked {
    color: #d9534f;
}

.comment-actions .reply-button {
    margin-left: 10px;
    cursor: pointer;
    color: #838383;
    font-family: "Garamond serif";
    text-decoration: underline;
}

.comment-children {
    margin-top: 10px;
    padding-left: 20px;
    border-left: 1px solid #ccc;
}