.micro-post {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); */
    border-radius: 10px;
}

.micro-post-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.micro-post-details h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
}

.micro-post-details p {
    font-size: 16px;
    margin-bottom: 10px;
}

.micro-post-details div {
    font-size: 16px;
    margin-bottom: 10px;
}

.micro-post-details a {
    display: inline-block;
    margin-top: 15px;
    font-size: 12px;
    color: #707070;
    transition: all 0.3s ease-in-out;
}

.micro-post-details a:hover {
    color: #0e153a;
}

@media screen and (min-width: 768px) {
    .micro-post {
        flex-direction: row;
    }

    .micro-post-details {
        flex: 1;
    }
}