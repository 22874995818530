.project {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); */
    border-radius: 10px;
}

.project-image {
    flex: 0 0 25%;
    max-width: 200px;
    max-height: 200px;
}

.project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.project-details h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
}

.project-details h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.project-details p {
    font-size: 16px;
    margin-bottom: 10px;
}

.project-details div {
    font-size: 16px;
    margin-bottom: 10px;
}

.project-details a {
    display: inline-block;
    margin-top: 15px;
    font-size: 12px;
    color: #707070;
    transition: all 0.3s ease-in-out;
}

.project-details a:hover {
    color: #0e153a;
}

@media screen and (min-width: 768px) {
    .project {
        flex-direction: row;
    }

    .project-image {
        width: 50%;
        max-width: none;
        max-height: none;
        margin-right: 20px;
    }

    .project-details {
        flex: 1;
    }
}