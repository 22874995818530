.landing-page {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 60px);
    font-family: "Garamond", serif;
    overflow-x: hidden;
}

.landing-page img {
    height: 100%;
}

.landing-page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 80px;
    flex: 1;
}

.landing-page-header h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

.landing-page-header p {
    font-size: 18px;
    margin-bottom: 20px;
    width: 69%;
    color: #707070;
}

.landing-page-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.social-links a {
    display: inline-block;
    margin: 0px 10px;
    font-size: 12px;
    color: #707070;
    transition: all 0.3s ease-in-out;
}

.social-links a:hover {
    color: #0e153a;
}

.landing-page-footer p {
    font-size: 14px;
    color: #707070;
}

.flip-container {
    perspective: 1000px;
    width: 100%; /* Ajusta según el tamaño de tu imagen */
    height: 30vh; /* Ajusta según el tamaño de tu imagen */
    margin: 0 auto; /* Centra el contenedor */
    cursor: pointer;
    margin-bottom: 20px;
}

.flipper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flipper.flipped {
    transform: rotateY(180deg);
}

.front,
.back {
    position: absolute;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

@media (max-width: 768px) {
    .landing-page-footer {
        position: inherit;
    }

    .landing-page img {
        height: 100%;
    }
}