.projects-page {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 60px);
    font-family: "Garamond", serif;
}

.projects-page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 60px;
    min-height: calc(100vh - 60px);
    flex: 1;
    width: 100%;
}

.projects-page-header h1 {
    font-size: 28px;
    margin-bottom: 20px;
}

.projects-page-header p {
    font-size: 18px;
    margin-bottom: 20px;
    color: #707070;
}

.projects-page-body {
    width: 90%;
    margin: auto;
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    overflow-y: scroll;
}